import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#eef',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const Jornadas = () => {
  return (
    <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
            <Typography variant='h5' style={{padding:'20px 20px'}}>
            International Workshop
            </Typography>
            <Typography variant='h4'color='#039be5' style={{padding:'20px 20px'}}>
            IMPACTS OF GLOBAL CHANGE IN WATER RESOURCE SYSTEMS DEPENDING ON NATURAL STORAGE FROM GROUNDWATER AND/OR SNOWPACKS
            </Typography>
            
            <Typography variant='p' style={{padding:'20px 20px'}}>
              Tuesday, September 27, 2022
              <h4> 
              Proyecto SIGLO-AN (IGME-CSIC): Plan Nacional de I+D+I
              </h4>             
            </Typography>

            <Typography variant='h6'color='#039be5' style={{padding:'20px 20px',textAlign:'left'}}>
              PROGRAM
            </Typography>

            <List sx={{ width: '100%', /* maxWidth: 760, */ bgcolor: 'background.paper' }}>
              <ListItem  alignItems="flex-start">
                <ListItemAvatar>
                  <Typography sx={{ display: 'inline' ,paddingRight:'15px'}} component="span" variant="body2" color="text.primary">
                  9:00-9:15.
                  </Typography>
                </ListItemAvatar>
                <div>
                <ListItemText 
                  primary="WELCOME ADDRESS. SIGLO-AN project" 
                  secondary="Speaker: David Pulido (IGME-CSIC, Spain)" 
                />
                <ListItemText 
                  secondary= {<a href="http://seguimientodenieve.es/workshop22/1. David_SIGLO-ANproject.pdf" target="_blank" download="David_SIGLO-ANproject.pdf"> Download presentation </a>}
                />
                </div>
              </ListItem>
            </List>


            <Typography variant='h6'color='#039be5' style={{padding:'20px 20px',textAlign:'left'}}>
              I. GLOBAL CHANGE & GROUNDWATER DEPENDENT SYSTEMS:
            </Typography>
               

            <List sx={{ width: '100%', /* maxWidth: 760, */ bgcolor: 'background.paper' }}>
              

              <ListItem>
                <ListItemAvatar>
                  <Typography sx={{ display: 'inline' ,paddingRight:'15px'}} component="span" variant="body2" color="text.primary">
                  9:15-9:25.
                  </Typography>
                </ListItemAvatar>
                <div>
                <ListItemText 
                  primary="National scale high resolution climate impact assessments on groundwater in Denmark." 
                  secondary="Speaker: Simon Stissen (GEUS, Denmark)" 
                />
                <ListItemText 
                  secondary= {<a href="http://seguimientodenieve.es/workshop22/2. SimonStisen SIGLO-AN 27sep2022.pdf" target="_blank" download="SimonStisen SIGLO-AN 27sep2022.pdf"> Download presentation </a>}
                />
                </div>
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Typography sx={{ display: 'inline' ,paddingRight:'15px'}} component="span" variant="body2" color="text.primary">
                  9:25-9:35.
                  </Typography>
                </ListItemAvatar>
                <div>
                <ListItemText 
                  primary="Groundwater Monitoring and assessing impacts of global change in water resource systems in France." 
                  secondary="Speaker: Laurence Gourcy (BRGM, France)" 
                />
                <ListItemText 
                  secondary= {<a href="http://seguimientodenieve.es/workshop22/3. Laurence_Etat des nappes d'eau souterraine_Septembre 2022.pdf" target="_blank" download="Laurence_Etat des nappes d'eau souterraine_Septembre 2022.pdf"> Download presentation </a>}
                />
                </div>
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Typography sx={{ display: 'inline' ,paddingRight:'15px'}} component="span" variant="body2" color="text.primary">
                  9:35-9:45.
                  </Typography>
                </ListItemAvatar>
                <div>
                <ListItemText 
                  primary="Some IGME experiences in the analyses of groundwater dependent systems in Spain." 
                  secondary="Speaker: Leticia Baena-Ruiz (IGME-CSIC, Spain)" 
                />
                <ListItemText 
                  secondary= {<a href="http://seguimientodenieve.es/workshop22/4. Leticia_SIGLO-AN.pdf" target="_blank" download="Leticia_SIGLO-AN.pdf"> Download presentation </a>}
                />
                </div>
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Typography sx={{ display: 'inline' ,paddingRight:'15px'}} component="span" variant="body2" color="text.primary">
                  9:45-9:55.
                  </Typography>
                </ListItemAvatar>
                <div>
                <ListItemText 
                  primary="Forecasting groundwater and surface water resources availibility using artificial intelligence-based models. Some 'success stories' in the Mediterranean Region." 
                  secondary="Speaker: Manuel Argamasilla (CetAqua, Spain)" 
                />
                <ListItemText 
                  secondary= {<a href="http://seguimientodenieve.es/workshop22/5. Manuel_Argamasilla_Cetaqua_Intern_Workshop_SIGLO-AN_CET.pdf" target="_blank" download="Manuel_Argamasilla_Cetaqua_Intern_Workshop_SIGLO-AN_CET.pdf"> Download presentation </a>}
                />
                </div>
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Typography sx={{ display: 'inline' ,paddingRight:'15px'}} component="span" variant="body2" color="text.primary">
                  9:55-10:05.
                  </Typography>
                </ListItemAvatar>
                <div>
                <ListItemText 
                  primary="Options and ideas for climate resilient development in the Upper Guadiana." 
                  secondary="Speaker: E. López Gunn (ICATALIST, Spain)" 
                />
                <ListItemText 
                  secondary= {<a href="http://seguimientodenieve.es/workshop22/6. ElenaLopezGumOptions and ideas for climate resilient development in (1).pdf" target="_blank" download="ElenaLopezGumOptions and ideas for climate resilient development in the Upper Guadiana.pdf"> Download presentation </a>}
                />
                </div>
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Typography sx={{ display: 'inline' ,paddingRight:'15px'}} component="span" variant="body2" color="text.primary">
                  10:05-10:15.
                  </Typography>
                </ListItemAvatar>
                <div>
                <ListItemText 
                  primary="Climate Services from a Water Systems Perspective. Contested knowledge for transformation." 
                  secondary="Speaker: María Mañez (Climate Service Center, Germany)" 
                />
                {/* <ListItemText 
                  secondary= {<a href="http://seguimientodenieve.es/workshop22/1. David_SIGLO-ANproject.pdf" target="_blank" download="1. David_SIGLO-ANproject.pdf"> Download presentation </a>}
                /> */}
                </div>
              </ListItem>

            </List>

            <Typography variant='h6'color='#039be5' style={{padding:'20px 20px',textAlign:'left'}}>
            BREAK
            </Typography>

            <Typography variant='h6'color='#039be5' style={{padding:'20px 20px',textAlign:'left'}}>
            II. GLOBAL CHANGE & AND WATER RESOURCES SYSTEM WITH A SIGNIFICANT SNOW COMPONENT
            </Typography>

            <List sx={{ width: '100%', /* maxWidth: 760, */ bgcolor: 'background.paper' }}>
              
              <ListItem>
                <ListItemAvatar>
                  <Typography sx={{ display: 'inline' ,paddingRight:'15px'}} component="span" variant="body2" color="text.primary">
                  10:45-10:55.
                  </Typography>
                </ListItemAvatar>
                <div>
                <ListItemText 
                  primary="Research on snow and ice in Northern Finland" 
                  secondary="Speaker: Björn Klöve; (University of Oulu; Finland)" 
                />
                {/* <ListItemText 
                  secondary= {<a href="http://seguimientodenieve.es/workshop22/1. David_SIGLO-ANproject.pdf" target="_blank" download="1. David_SIGLO-ANproject.pdf"> Download presentation </a>}
                /> */}
                </div>
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Typography sx={{ display: 'inline' ,paddingRight:'15px'}} component="span" variant="body2" color="text.primary">
                  10:55-11:05.
                  </Typography>
                </ListItemAvatar>
                <div>
                <ListItemText 
                  primary="Use of Satellite Products in Hydrological Model Applications." 
                  secondary="Speaker: A. Sensoy (Eskişehir Technical University, Turkey)" 
                />
                <ListItemText 
                  secondary= {<a href="http://seguimientodenieve.es/workshop22/9. Aynur_Sensoy_WorkShop_27Sep2022_ASensoy_v1.pdf" target="_blank" download="Aynur_Sensoy_WorkShop_27Sep2022_ASensoy_v1.pdf"> Download presentation </a>}
                />
                </div>
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Typography sx={{ display: 'inline' ,paddingRight:'15px'}} component="span" variant="body2" color="text.primary">
                  11:05-11:15.
                  </Typography>
                </ListItemAvatar>
                <div>
                <ListItemText 
                  primary="The Distance Distribution Dynamics (DDD) rainfall runoff model- a parameter parsimonious model suitable for predictions in ungauged basins." 
                  secondary="Speaker: Thomas Skaugen (Norwegian Water Resources and Energy Directorate, Norwey)" 
                />
                <ListItemText 
                  secondary= {<a href="http://seguimientodenieve.es/workshop22/10. Thomas Skaugen_THS_HydSimOveralt_Siglo-AN2709_22.pdf" target="_blank" download="Thomas Skaugen_THS_HydSimOveralt_Siglo-AN2709_22.pdf"> Download presentation </a>}
                />
                </div>
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Typography sx={{ display: 'inline' ,paddingRight:'15px'}} component="span" variant="body2" color="text.primary">
                  11:15-11:25.
                  </Typography>
                </ListItemAvatar>
                <div>
                <ListItemText 
                  primary="The role of snow-fed mountain water towers for a growing world population." 
                  secondary="Speaker: Carmen de Jong (University of Strasbourg, France)" 
                />
                <ListItemText 
                  secondary= {<a href="http://seguimientodenieve.es/workshop22/11. Carmen_DeJong_The role of snow covered areas for a growing world population de Jong.pdf" target="_blank" download="Carmen_DeJong_The role of snow covered areas for a growing world population.pdf"> Download presentation </a>}
                />
                </div>
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Typography sx={{ display: 'inline' ,paddingRight:'15px'}} component="span" variant="body2" color="text.primary">
                  11:25-11:35.
                  </Typography>
                </ListItemAvatar>
                <div>
                <ListItemText 
                  primary="Some IGME experiences in the study of snow hydrology problems" 
                  secondary="Speaker: Antonio Collados Lara (UGR, Spain)" 
                />
                <ListItemText 
                  secondary= {<a href="http://seguimientodenieve.es/workshop22/12. Antonio Collados_IGME-climate-snow.pdf" target="_blank" download="Antonio Collados_IGME-climate-snow.pdf"> Download presentation </a>}
                />
                </div>
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Typography sx={{ display: 'inline' ,paddingRight:'15px'}} component="span" variant="body2" color="text.primary">
                  11:35-11:45.
                  </Typography>
                </ListItemAvatar>
                <div>
                <ListItemText 
                  primary="Techniques for the quantification of ice movement based on synthetic aperture radar sensors." 
                  secondary="Speaker: Oriol Monserrat; (Centro Tecnológico de Telecomunicaciones de Cataluña)" 
                />
                <ListItemText 
                  secondary= {<a href="http://seguimientodenieve.es/workshop22/13. Oriol_SIGLO-AN_Glaciares.pdf" target="_blank" download="Oriol_SIGLO-AN_Glaciares.pdf"> Download presentation </a>}
                />
                </div>
              </ListItem>

            </List>

            <Typography variant='h6'color='#039be5' style={{padding:'20px 20px',textAlign:'left'}}>
            BREAK
            </Typography>

            <Typography variant='h6'color='#039be5' style={{padding:'20px 20px',textAlign:'left'}}>
            III. RECENT AND FUTURE EU PARTNERSHIPS & CSA:
            </Typography>

            <List sx={{ width: '100%', /* maxWidth: 760, */ bgcolor: 'background.paper' }}>
              
              <ListItem>
                <ListItemAvatar>
                  <Typography sx={{ display: 'inline' ,paddingRight:'15px'}} component="span" variant="body2" color="text.primary">
                  12:15-12:25.
                  </Typography>
                </ListItemAvatar>
                <div>
                <ListItemText 
                  primary="Water4All partnership." 
                  secondary="Speaker: Dominique Darmendrail (BRGM, France)" 
                />
                <ListItemText 
                  secondary= {<a href="http://seguimientodenieve.es/workshop22/14. Dominique D. Water4All in brief Sept22_for ES Workshop.pdf" target="_blank" download="Dominique D. Water4All in brief Sept22_for ES Workshop.pdf"> Download presentation </a>}
                />
                </div>
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Typography sx={{ display: 'inline' ,paddingRight:'15px'}} component="span" variant="body2" color="text.primary">
                  12:25-12:35.
                  </Typography>
                </ListItemAvatar>
                <div>
                <ListItemText 
                  primary="A geological service for Europe (GSEU)." 
                  secondary="Speaker: Klaus Hinsby (GEUS, Denmark)" 
                />
                <ListItemText 
                  secondary= {<a href="http://seguimientodenieve.es/workshop22/15. Klaus_Hinsby_EGDI_GeoERA_GSEU intro _SIGLO-AN 2022.pdf" target="_blank" download="Klaus_Hinsby_EGDI_GeoERA_GSEU intro _SIGLO-AN 2022.pdf"> Download presentation </a>}
                />
                </div>
              </ListItem>

            </List>

            <Typography variant='h6'color='#039be5' style={{padding:'20px 20px',textAlign:'left'}}>
              12:35. ROUND TABLE & CLOSING SESSION
            </Typography>


            <Item style={{marginBottom:'10px'}}>
                <Typography variant='body2'style={{padding:'0px 20px', textAlign:'left'}}>
                <h4>Organizing Committee:</h4>
                    <strong>D Pulido-Velazquez </strong>(Chair, Senior Researcher (SR) IGME-CSIC); AJ Collados Lara, Post-Doc, UGR; L Baena Ruiz, Post-Doc, IGME-CSIC; A de la Hera Portillo, SR, IGME-CSIC; RM Mateos Ruiz, SR, IGME-CSIC; JL García Arostegui, SR, IGME-CSIC; J Gómez Gómez, Technician IGME-CSIC; F Delgado Ramos, Associate Professor, UGR
                    
                </Typography>
            </Item>
            <Item style={{marginBottom:'10px'}} >
                <Typography variant='body2'style={{padding:'0px 20px', textAlign:'left'}}>
                <h4>DOWNLOAD ALL PRESENTATIONS</h4>
                {<a href="../workshop22/PDF.zip" download="Presentations_Workshop_SIGLO_AN2022.zip"> Download presentations </a>}
                </Typography>
            </Item>
            <Item style={{marginBottom:'10px'}} >
                <Typography variant='body2'style={{padding:'0px 20px', textAlign:'left'}}>
                <h4>Venue</h4>
                Online meeting
                </Typography>
            </Item>

            <Item style={{marginBottom:'10px'}} >
                <Typography variant='body2'style={{padding:'0px 20px', textAlign:'left'}}>
                <h4>Registration</h4>
                Free registration<br></br>
                <strong>Deadline for registration: September 25, 2022</strong>
                </Typography>
                {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScWAHqJ0BL9R92jPz1fr8wtJKo-jyEt7IbKB4czFt8eDJ29pA/viewform?embedded=true" width="640" height="950" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe> */}
            </Item>


            </Grid>
        </Grid>
        <Grid item xs={12} >
          <Item > 
          <Typography variant='p'  style={{padding:'50px 20px'}}>
          <p>SIGLO-AN (RTI2018-101397-B-I00) financiado por el Programa Estatal de I+D+I orientada a los Retos de la Sociedad
          </p><p>
            Instituto Geológico y Minero de España (IGME-CSIC)
            Departamento de Aguas y Cambio Global
            Urbanización Alcázar del Genil nº 4. Edificio Zulema. Bajo. 18006 Granada
            </p><p>
            "info@seguimientodenieve.es" 
            "d.pulido@igme.es"</p>
            </Typography>
          </Item>
        </Grid>
    </Box>
  )
}